.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 2000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 500px;
    width: 100%;
    padding: 40px;
    background-color: rgb(var(--color-white));
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(var(--color-black), var(--alpha-50));
    transition: opacity 200ms ease-in-out;
}

@media screen and (max-width: 576px) {
    .Modal {
        padding: 20px;
    }
}